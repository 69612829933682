import {
  configureStore,
  isFulfilled,
  isPending,
  isRejected,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import { userReducer, setUser } from './slice/userSlice';
import { roleReducer } from './slice/roleSlice';
import { loadingReducer, setLoading } from './slice/loadingSlice';
import { setLightTheme, themeReducer } from './slice/themeSlice';
import { QApi } from './baseQuery';
import Toast from 'react-native-toast-message';
import { errorLogOnServer, removeItemLocalStorage } from '../utils';
import { deleteFirebaseToken } from '../../firebase';
import { unreadReducer } from './slice/unreadSlice';
import firstLoginModalReducer from './slice/firstLoginModalSlice';

const loadingPrevent = [
  'getQueueManagedList',
  'getUserQueueJoinedList',
  'getNotificationList',
  'getUnreadCount',
];

const rtkQueryErrorLogger = (api) => (next) => async (action) => {
  if (isRejectedWithValue(action)) {
    if (action.payload.status === 401 || action.payload.status === 403) {
      await removeItemLocalStorage('fcm_token');
      await removeItemLocalStorage('token');
      await removeItemLocalStorage('role');
      await deleteFirebaseToken();
      api.dispatch(setUser(null));
      api.dispatch(setLightTheme(false));
    } else if (
      (action?.payload?.status === 400 ||
        action?.payload?.status === 404 ||
        action?.payload?.status === 422) &&
      action?.payload?.data?.detail
    ) {
      Toast.show({
        type: 'error',
        text1: 'Oops!',
        text2:
          action?.payload?.status === 422
            ? 'Error Q422'
            : action?.payload?.data?.detail,
      });
      errorLogOnServer(action);
    } else {
      Toast.show({
        type: 'error',
        text1: 'Oops!',
        text2: 'Something went wrong',
      });
      errorLogOnServer(action);
    }
  }
  return next(action);
};

export const genericLoaderMiddleware = (store) => (next) => (action) => {
  if (action?.meta?.arg?.endpointName) {
    if (loadingPrevent.includes(action?.meta?.arg?.endpointName)) {
      return next(action);
    }
  }
  if (isPending(action)) {
    store.dispatch(setLoading(true));
  }
  if (
    isFulfilled(action) ||
    isRejected(action) ||
    isRejectedWithValue(action)
  ) {
    store.dispatch(setLoading(false));
  }
  return next(action);
};

export const store = configureStore({
  reducer: {
    [QApi.reducerPath]: QApi.reducer,
    user: userReducer,
    isLoading: loadingReducer,
    isLightTheme: themeReducer,
    role: roleReducer,
    unreadCount: unreadReducer,
    firstLoginModal: firstLoginModalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(QApi.middleware)
      .concat(rtkQueryErrorLogger)
      .concat(genericLoaderMiddleware),
});
