import { createSlice } from '@reduxjs/toolkit';

const firstLoginModalSlice = createSlice({
  name: 'firstLoginModal',
  initialState: {
    hasShownFirstTimeModal: false,
  },
  reducers: {
    setFirstLoginModalShown: (state, action) => {
      state.hasShownFirstTimeModal = action.payload;
    },
  },
});

export const { setFirstLoginModalShown } = firstLoginModalSlice.actions;
export default firstLoginModalSlice.reducer;
