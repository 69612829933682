import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function InfoIcon({ width = 24, height = 24, color = 'black' }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <Path d="M0 0h24v24H0z" fill="none" />
      <Path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
        fill={color}
      />
    </Svg>
  );
}

export default InfoIcon;
