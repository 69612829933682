export const USER_LOGIN = 'user/login';
export const VERIFY_LOGIN = 'user/login/verify';
export const USER_GET_DETAIL = 'user/me';
export const JOIN_QUEUE = 'user/join-queue';
export const USER_LEAVE_QUEUE = 'user/leave-queue';

export const UPDATE_PROFILE = 'user/me';
export const LOGOUT = 'user/logout';

export const BUSINESS_DETAIL_CREATE = 'business';
export const BUSINESS_DETAIL_UPDATE = 'business';

export const CREATE_QUEUE = 'queue';
export const UPDATE_QUEUE = 'queue';
export const GET_QUEUE_DETAIL = 'queue';
export const ADD_USER_QUEUE = 'queue';
export const GET_APPOINTMENT_SLOT_LIST = 'queue';
export const DELETE_QUEUE = 'queue';
export const DOWNLOAD_QR_CODE_PDF = 'queue';
export const QUEUE_USER_CONFIRM_CHECK_AVAIBILITY = 'queue';

export const QUEUE_USER_ADD_MOBILE_NUMBER_OWNER = 'queue/queue-user';
export const BATCH_BOOKING_SLOT = 'queue';

export const QUEUE_USER_ADD_MOBILE_NUMBER_GUEST = 'queue-user';
export const GET_USER_QUEUE_DETAIL = 'queue-user';

export const GET_QUEUE_MANAGED_LIST = 'ui/queues-managed';
export const GET_USER_QUEUE_JOINED_LIST = 'ui/queues-joined';
export const GET_QUEUE_ACTION_DETAIL = 'ui/queue';
export const HELP_AND_SUPPORT = 'ui/help-and-support';
export const FEEDBACK = 'ui/feedback';
export const GET_USER_JOIN_DROP_DOWN_LIST = 'ui/queues-joined-droplist';
export const GET_REPORT_DATA = 'ui/report-form/data';
export const GENERATE_REPORT = 'ui/report/data';
export const CONTACT_DETAIL_SEND = 'ui/contact-us';

export const SEND_OTP_FOR_ANONYMOUS_USER = 'otp/send';

export const GET_NOTIFICATION_LIST = 'notification';
export const DELETE_NOTIFICATION = 'notification';
export const UNREAD_COUNT = 'notification/unread-count';

export const SEND_OTP = 'user/send_otp';
export const GET_QUEUE_LIST = 'queue/list';
export const GET_USER_QUEUE_LIST = 'queue_user/list';
export const ASSIGN_USER_TO_QUEUE = 'queue/add_user_in_queue';
export const UPDATE_QUEUE_STATUS = 'queue/action';
export const SKIP_USER_QUEUE = 'queue/skip_user';
export const MOVE_TO_END_QUEUE = 'queue/move_to_last_user';
export const MOVE_TO_WAITING_QUEUE = 'queue/move_to_waiting_user';
export const REMOVE_USER_QUEUE = 'queue/remove_user';
export const BATCH_PROCESS_ACTION = 'queue/batch_action';
export const BATCH_SERVING_USER_ACTION = 'queue/serving_batch_user_action';
export const UNDO_PROCESS_ACTION = 'queue/undo_user';
export const GET_USER_QUEUE_SLOT_DETAIL = 'queue/slot_details';
export const GET_SUBSCRIPTION_PLAN_LIST = 'user/subscription_plan';
export const USER_SUBSCRIBE_PLAN = 'user/user_subscribe';
export const GET_PAYMENT_HISTORY_LIST = 'user/payment_history';
export const CONTINUE_JOIN_EXISTING_USER =
  'queue_user/continue_joining_with_existing_user';
export const UPDATE_USER_SERVING_TIME = 'queue/update_serving_time';
export const GET_ADVANCED_BOOKING_SLOT_DETAIL = 'queue/booking_slots';
export const ADD_APPOINTMENT_USER_REQUEST_TO_QUEUE =
  'queue/add_user_appointment_request';
export const GET_APPOINTMENT_USER_LIST = 'queue/fetch_appointment_details';
export const MANAGE_APPOINTMENT_ACTION = 'queue/manage_user_appointment';
export const USER_QUEUE_INTEREST_DETAIL = 'queue_user/interested';
export const FETCH_USER_INTEREST_INTO_QUEUE =
  'queue_user/fetch_interested_users';
export const SEAT_UPDATE_SERVING_CAPACITY = 'queue/update';
export const LEAVE_QUEUE_WITHOUT_AUTH = 'queue_user/left_user_anonymous';
export const GET_UI_STATS = 'ui/stats';
export const GET_BLOGS = 'blogs';
