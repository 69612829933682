import { configureFonts } from 'react-native-paper';
import { DefaultTheme } from 'react-native-paper';
const fontConfig = {
  fontFamily: 'Poppins',
};
const lightTheme = {
  ...DefaultTheme,
  roundness: 5,
  colors: {
    ...DefaultTheme.colors,
    primary: '#12B76A',
    background: '#F3F4F6',
    onBackground: '#ffffff',
    onBackgroundDark: '#ffffff',
    OnBackgroundDarkBorder: '#E9E9E9',
    blackTxt: '#090D13',
    whiteTxt: '#ffffff',
    text: '#808080',
    secondaryText: '#090D13',
    inputTxtColor: '#000000',
    detailText: '#D4D4D4',
    errorColor: '#B3261E',
    blueColor: '#2970FF',
    placeHolderText: '#4E5574',
    white: '#F4F4F4',
    redColor: '#FF4545',
    orangeColor: '#F97315',
    borderColor: '#E9E9E9',
    deviderColor: '#151B27',
    disabledIconColor: '#A1A5AD',
    surface: '#ffffff',
    onSurface: '#000000',
    onSurfaceVariant: '#4E5574',
    surfaceVariant: '#4E5574',
    primaryContainer: '#12B76A',
    onPrimaryContainer: '#ffffff',
    accent: '#12B76A',
    secondaryContainer: '#12B76A',
    outlineVariant: '#1E243D',
    Waiting: '#12B76A',
    Staging: '#FFAA10',
    Serving: '#576AB3',
    Completed: '#095b35',
    Removed: '#EF3329',
    Skipped: '#B3261E',
    Exited: '#EF3329',
    Confirmed: '#12B76A',
    Unconfirmed: '#FEFF04',
    Buffer: '#FF8D5C',
    humanColor: '#d99e38',
    batchColor: '#313beb',
    appointmentColor: '#b668cc',
    disabledColor: '#1B4D3E',
    disabledTextColor: '#ffffff',
    landing: '#009688',
    landingText: '#000000',
    landingGreen: '#159157',
    landingLightBlue: '#063366',
    landingShadowBlue: '#011B3B',
    modalOverlay: '#000000CC',
    landingLightBlue: '#0E2C4E',
    headerBlue: '#042142',
  },
  fontWeight: {
    button: 600,
    label: 500,
  },
  fontSize: {
    label: 13,
  },
  fonts: configureFonts({ config: fontConfig }),
};

const darkTheme = {
  ...DefaultTheme,
  roundness: 5,
  colors: {
    ...DefaultTheme.colors,
    primary: '#12B76A',
    background: '#090D13',
    onBackground: '#111523',
    onBackgroundDark: '#111523',
    OnBackgroundDarkBorder: '#1E243D',
    blackTxt: '#090D13',
    whiteTxt: '#ffffff',
    text: '#BAC0CE',
    secondaryText: '#ffffff',
    inputTxtColor: '#ffffff',
    detailText: '#D4D4D4',
    errorColor: '#B3261E',
    blueColor: '#2970FF',
    placeHolderText: '#C0C0C0',
    white: '#F4F4F4',
    redColor: '#FF4545',
    orangeColor: '#F97315',
    borderColor: '#1E243D',
    deviderColor: '#151B27',
    disabledIconColor: '#A1A5AD',
    surface: '#111523',
    onSurface: '#ffffff',
    onSurfaceVariant: '#ffffff',
    surfaceVariant: '#ffffff',
    primaryContainer: '#12B76A',
    onPrimaryContainer: '#ffffff',
    accent: '#12B76A',
    secondaryContainer: '#12B76A',
    outlineVariant: '#1E243D',
    Waiting: '#12B76A',
    Staging: '#FFAA10',
    Serving: '#576AB3',
    Completed: '#095b35',
    Removed: '#BF2F2D',
    Skipped: '#BF4A56',
    Exited: '#BF2F2D',
    Confirmed: '#12B76A',
    Unconfirmed: '#FEFF04',
    Buffer: '#FF8D5C',
    humanColor: '#d99e38',
    batchColor: '#313beb',
    appointmentColor: '#b668cc',
    disabledColor: '#1B4D3E',
    disabledTextColor: '#ffffff',
    landing: '#009688',
    landingText: '#ffffff',
    landingGreen: '#159157',
    landingLightBlue: '#063366',
    landingShadowBlue: '#011B3B',
    modalOverlay: '#000000CC',
    landingLightBlue: '#0E2C4E',
    headerBlue: '#042142',
  },
  fontWeight: {
    button: 600,
    label: 500,
  },
  fontSize: {
    label: 13,
  },
  fonts: configureFonts({ config: fontConfig }),
};

export { lightTheme, darkTheme };
