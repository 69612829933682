import React, { useState, useEffect, Suspense, lazy } from 'react';
// import { StatusBar } from 'expo-status-bar';
import { AppRegistry, Platform } from 'react-native';
import * as Font from 'expo-font';
import { PaperProvider } from 'react-native-paper';
import {
  SafeAreaProvider,
  SafeAreaView,
  useSafeAreaInsets,
} from 'react-native-safe-area-context';
import appInfo from './app.json';
import { lightTheme, darkTheme } from './src/utils/theme';
import { store } from './src/store/store';
import { Provider, useSelector, useDispatch } from 'react-redux';
import Toast from 'react-native-toast-message';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { setLightTheme } from './src/store/slice/themeSlice';
import * as SplashScreen from 'expo-splash-screen';
import './i18nextInit';
import {
  getRoleLocalStorage,
  getToken,
  setRoleLocalStorage,
  setToken,
} from './src/utils';
import { UserApi } from './src/store/api/UserApi';
import { setUser } from './src/store/slice/userSlice';
import { registerServiceWorker } from './serviceWorker';
import { setRole } from './src/store/slice/roleSlice';
import * as Sentry from 'sentry-expo';
import { SENTRY_DSN_URL, SENTRY_EXPO_DEVELOPMENT, SENTRY_DEBUG } from '@env';
import { messaging } from './firebase';
import { onMessage } from 'firebase/messaging';
import { toastConfig } from './src/config/ToastConfig';
import Constants from 'expo-constants';

Sentry.init({
  dsn: SENTRY_DSN_URL,
  enableInExpoDevelopment: SENTRY_EXPO_DEVELOPMENT,
  debug: SENTRY_DEBUG,
  tracesSampleRate: 1.0,
});

SplashScreen.preventAutoHideAsync();

const Navigations = lazy(() => import('./src/navigations'));
const Loader = lazy(() => import('./src/components/Loader'));
const BusinessDetailModal = lazy(
  () => import('./src/components/BusinessDetailModal')
);
const PNotification = lazy(() => import('./src/components/PNotification'));

function App() {
  const [isShowNotification, setIsShowNotification] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const isLoading = useSelector((state) => state.isLoading);
  const isLightTheme = useSelector((state) => state.isLightTheme);
  const [isLoadAssetComplete, setLoadAssetComplete] = useState(false);
  const [isShowBusinessModal, setIsShowBusinessModal] = useState(false);
  const currentRole = useSelector((state) => state.role);
  const currentUserDetail = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const insets = useSafeAreaInsets();

  useEffect(() => {
    if (currentRole === 'business' && currentUserDetail) {
      if (!currentUserDetail?.business) {
        setIsShowBusinessModal(true);
      } else {
        setIsShowBusinessModal(false);
      }
    } else {
      setIsShowBusinessModal(false);
    }
  }, [currentRole, currentUserDetail]);

  const onCloseBusinessModal = async () => {
    dispatch(setRole('user'));
    setRoleLocalStorage('user');
  };

  const onSuccessBusinessModal = async () => {
    setIsShowBusinessModal(false);
  };

  const onMessageListener = (async () => {
    const messagingResolve = await messaging;
    if (messagingResolve) {
      onMessage(messagingResolve, (payload) => {
        setIsShowNotification(true);
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        });
      });
    }
  })();

  useEffect(() => {
    async function prepare() {
      try {
        const token = await getToken();
        if (token) {
          const userDetailResponse = await dispatch(
            UserApi.endpoints.getUserDetail.initiate()
          );
          if (userDetailResponse && userDetailResponse?.data) {
            dispatch(setUser(userDetailResponse?.data));
          }
        }
        let rLightTheme = await AsyncStorage.getItem('isLightTheme');
        if (token && rLightTheme == 'true') {
          store.dispatch(setLightTheme(true));
        } else {
          store.dispatch(setLightTheme(false));
        }
        let role = await getRoleLocalStorage();
        if (role) {
          if (role === 'Owner') {
            role = 'business';
            await setRoleLocalStorage('business');
          }
          if (role === 'User') {
            role = 'user';
            await setRoleLocalStorage('user');
          }
          store.dispatch(setRole(role));
        } else {
          store.dispatch(setRole('user'));
        }

        await Promise.all([
          Font.loadAsync({
            Poppins: require('./src/assets/fonts/Poppins-Regular.ttf'),
          }),
        ]);

        await SplashScreen.hideAsync();
      } catch (e) {
        console.warn(e);
      } finally {
        setLoadAssetComplete(true);
      }
    }

    prepare();
  }, []);

  if (!isLoadAssetComplete) {
    if (Platform.OS === 'web') {
      return (
        <SafeAreaView
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <Loader />
          </Suspense>
        </SafeAreaView>
      );
    } else {
      return null;
    }
  }

  return (
    <>
      <PaperProvider theme={isLightTheme ? lightTheme : darkTheme}>
        <SafeAreaProvider>
          <SafeAreaView
            style={{ flex: 1, paddingTop: Constants.statusBarHeight }}
          >
            {/* <StatusBar style="light" /> */}
            <Navigations />
            <PNotification
              notification={notification}
              isShowNotification={isShowNotification}
              hideNotification={() => setIsShowNotification(false)}
            />
            <BusinessDetailModal
              showBusinessModal={isShowBusinessModal}
              hideBusinessModal={onCloseBusinessModal}
              onSuccessBusinessModal={onSuccessBusinessModal}
            />
          </SafeAreaView>
        </SafeAreaProvider>
      </PaperProvider>
      <Toast config={toastConfig} />
      {isLoading && (
        <Suspense fallback={<div>Loading...</div>}>
          <Loader />
        </Suspense>
      )}
    </>
  );
}

export default function AppWrapper() {
  return (
    <SafeAreaProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </SafeAreaProvider>
  );
}

AppRegistry.registerComponent(appInfo.expo.name, () => AppWrapper);
registerServiceWorker();
